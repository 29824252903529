/*jshint strict: false */
(
    function (window, document, $, _, undefined) {
        function setIframeContent(e) {
            if (_.has(e, 'relatedTarget')) {
                var trigger = $(e.relatedTarget),
                    iFrameContent = trigger.data('course-address'),
                    iFrameTitle = trigger.data('course-title'),
                    courseId = trigger.data('course-id'),
                    courseSlug = trigger.data('course-slug'),
                    modal = $(this),
                    body = modal.find('.modal-body'),
                    title = modal.find('.modal-title'),
                    iFrame;

                if (trigger.hasClass('quiz-launcher')) {
                    iFrameTitle += ' - Quiz';
                }

                if (!_.isEmpty(iFrameContent)) {
                    iFrame = $('<iframe></iframe>');
                    iFrame.attr('src', iFrameContent)
                        .attr('title', iFrameTitle)
                        .attr('allowfullscreen', 'allowfullscreen');

                    body.append(iFrame);
                    title.text(iFrameTitle);
                    modal.data('course-id', courseId);
                    modal.attr('data-course-id', courseId);
                    modal.data('course-slug', courseSlug);
                    modal.attr('data-course-slug', courseSlug);
                }
            }
        }

        function setIframeHeight(modal) {
            var body = modal.find('.modal-body'),
                iFrame = body.find('iframe'),
                aspect = 1200 / 675;

            if (modal.hasClass('show') && iFrame.length > 0) {
                iFrame.css('height', (iFrame.width() / aspect).toString() + 'px');
            }
        }

        function setIframeSize() {
            var modal = $(this);

            setIframeHeight(modal);

            var debounceFunction = function (m) {
                return function () {
                    setIframeHeight(m);
                };
            };

            $(window).on('resize.one-health', _.debounce(debounceFunction(modal), 150));
        }

        function clearIframeContent() {
            var modal = $(this);

            modal.find('.modal-body').html('');
            $(window).off('resize.one-health');
        }

        function checkCourseStatus(e) {
            if ($('a.course-status').length > 0) {
                var link = $(this).data('status-url'),
                    course = $(this).data('course-id'),
                    courseSlug = $(this).data('course-slug');

                $.ajax(
                    {
                        url: link,
                        type: 'POST',
                        dataType: "json",
                        data: {
                            courseId: course
                        }
                    }
                )
                    .done(
                        function(data) {
                            if (!_.isEmpty(data)) {
                                if (_.has(data, 'statusBtn')) {
                                    if (!_.isEmpty(data.statusBtn)) {
                                        var courseLauncher = $('a.course-launcher[data-course-slug="' + courseSlug + '"]');

                                        if (courseLauncher.length > 0) {
                                            var statusLauncher = courseLauncher.filter('.course-start, .course-resume');

                                            if (statusLauncher.length > 0) {
                                                statusLauncher.removeClass(['course-start', 'course-resume', 'course-retake']);
                                                statusLauncher.addClass(data.statusBtn.class);
                                                statusLauncher.text(data.statusBtn.text);

                                                if (_.has(data, 'completeBadge')) {
                                                    var transcriptBtn = statusLauncher.parent().find('.btn-transcript'),
                                                        completeBadge = statusLauncher.parent().find('.course-complete');

                                                    if (transcriptBtn.length > 0 && completeBadge.length === 0) {
                                                        $(data.completeBadge).insertBefore(transcriptBtn);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                if (_.has(data, 'certificateSlug')) {
                                    checkCertificateStatus(data.certificateSlug);
                                }
                            }
                        }
                    )
                    .fail(
                        function (xhr, status, error) {
                            console.log(xhr);
                            console.log(status);
                            console.log(error);
                        }
                    );
            }
        }

        function checkQuizStatus(e) {
            var link = $(this).data('status-url'),
                course = $(this).data('course-id'),
                courseSlug = $(this).data('course-slug');

            $.ajax(
                {
                    url: link,
                    type: 'POST',
                    dataType: "json",
                    data: {
                        courseId: course
                    }
                }
            )
                .done(
                    function(data) {
                        var quizLauncher;

                        if (!_.isEmpty(data)) {
                            if (_.has(data, 'statusBtn')) {
                                if (!_.isEmpty(data.statusBtn)) {
                                    quizLauncher = $('a.quiz-launcher[data-course-slug="' + courseSlug + '"]');

                                    if (quizLauncher.length > 0) {
                                        if (!_.isEmpty(data.statusBtn.class)) {
                                            if (!quizLauncher.hasClass(data.statusBtn.class)) {
                                                quizLauncher.addClass(data.statusBtn.class);
                                            }
                                        }

                                        quizLauncher.text(data.statusBtn.text);
                                    }
                                }
                            }

                            if (_.has(data, 'completeBadge')) {
                                if (_.isUndefined(quizLauncher)) {
                                    quizLauncher = $('a.quiz-launcher[data-course-slug="' + courseSlug + '"]');
                                }

                                var transcriptBtn = quizLauncher.parent().find('.btn-transcript'),
                                    completeBadge = quizLauncher.parent().find('.course-complete'),
                                    courseLauncher = $('a.course-launcher[data-course-slug="' + courseSlug + '"]');


                                if (transcriptBtn.length > 0 && completeBadge.length === 0) {
                                    $(data.completeBadge).insertBefore(transcriptBtn);
                                    quizLauncher.remove();

                                    if (courseLauncher.length > 0) {
                                        var statusLauncher = courseLauncher.filter('.course-start, .course-resume');

                                        if (statusLauncher.length > 0) {
                                            statusLauncher.removeClass(['course-start', 'course-resume', 'course-retake']);
                                            statusLauncher.addClass('course-retake');
                                            statusLauncher.text('View Course Again');
                                        }
                                    }
                                }
                            }

                            if (_.has(data, 'certificateSlug')) {
                                checkCertificateStatus(data.certificateSlug);
                            }
                        }
                    }
                )
                .fail(
                    function (xhr, status, error) {
                        console.log(xhr);
                        console.log(status);
                        console.log(error);
                    }
                );
        }

        function checkCertificateStatus(certificateSlug) {
            var certCta = $('#' + certificateSlug),
                link = $(certCta).data('status-url'),
                certificate = $(certCta).data('certificate-id');

            $.ajax(
                {
                    url: link,
                    type: 'POST',
                    dataType: "json",
                    data: {
                        certificateId: certificate
                    }
                }
            )
                .done(
                    function(data) {
                        if (!_.isEmpty(data)) {
                            if (_.has(data, 'status')) {
                                if (data.status) {
                                    var printBtn = certCta.find('.btn-print.disabled');

                                    if (printBtn.length > 0) {
                                        printBtn.removeClass('disabled');
                                        printBtn.attr('aria-disabled', false);
                                    }
                                }
                            }
                        }
                    }
                )
                .fail(
                    function (xhr, status, error) {
                        console.log(xhr);
                        console.log(status);
                        console.log(error);
                    }
                );
        }

        $(document).ready(
            function () {
                var modal = $('#marketCourseModal'),
                    quizModal = $('#marketQuizModal');

                modal.on('show.bs.modal', setIframeContent);
                modal.on('shown.bs.modal', setIframeSize);
                modal.on('hide.bs.modal', checkCourseStatus);
                modal.on('hidden.bs.modal', clearIframeContent);

                quizModal.on('show.bs.modal', setIframeContent);
                quizModal.on('shown.bs.modal', setIframeSize);
                quizModal.on('hide.bs.modal', checkQuizStatus);
                quizModal.on('hidden.bs.modal', clearIframeContent);
            }
        );
    }
)(window, document, window.jQuery, window._);
